<template>
	<div v-show="canHomeShow" class="container-sm mt-5">
		<div class="fixed-Area">
			<el-button
				class="freeTryBtn-fixed mb-2"
				@click="toPage('/ChargeMeContactBook/ClassRoomCenter')"
				size="mini"
			>
				免費使用</el-button
			>
			<el-button
				class="freeTryBtn-fixed lineBackground ms-0"
				@click="toPageLine()"
			>
				加入喬米GPT
			</el-button>
		</div>
		<section class="">
			<div class="row align-items-center justify-content-center">
				<div class="col-12 mb-2">
					<div class="text-center">
						<h1 class="bigSlogan">
							解決90%老師的困擾<br />
							不被教務綁架你的時間
						</h1>
						<p class="smallSlogan">簡單好上手的課務管理系統</p>

						<h1 class="d-none">
							聯絡簿、電子聯絡簿
							作業指派、作業上傳、問卷回條、回傳檔案、作業清單
							協同合作、家長與老師、溝通平台、隨時檢視
							即時通知、逾期通知、繳費通知、自動通知
						</h1>
						<el-button
							class="freeTryBtn"
							@click="toPage('/ChargeMeContactBook/ClassRoomCenter')"
						>
							免費使用</el-button
						>
					</div>

					<el-image
						class="featureImage bg-white"
						:src="
							require('../../assets/image/ChargeMeContactBook/Home/Hero.svg')
						"
					></el-image>
				</div>
			</div>
		</section>
		<section>
			<!-- <el-divider class="my-0" content-position="center"></el-divider> -->

			<div class="align-items-center pt-5 mt-5">
				<div class="row">
					<div class="col-12 mb-2">
						<h1 class="bigSlogan">
							你的課務系統，
							<br v-if="$store.getters.mobileSize" />貴又無法省時間嗎？

							<p class="smallSlogan">除了教學 你的時間應該只屬於你自己!</p>
						</h1>
					</div>
					<div class="col-12 mb-2 d-flex justify-content-around flex-wrap">
						<el-button class="toolIntroduceBtn" link>
							<div class="toolIntroduceBtnInfo">
								<i
									class="fa fa-tv toolIntroduceIcon"
									style="color: #4d81ea"
								></i>
								<p class="mt-2 toolIntroduceText">客製化主頁</p>
							</div>
						</el-button>
						<el-button class="toolIntroduceBtn ms-0" link>
							<div class="toolIntroduceBtnInfo">
								<i
									class="far fa-book-open toolIntroduceIcon"
									style="color: #ecb240"
								></i>
								<p class="mt-2 toolIntroduceText">派發作業系統</p>
							</div>
						</el-button>
						<el-button class="toolIntroduceBtn ms-0" link>
							<div class="toolIntroduceBtnInfo">
								<i
									class="fa fa-user-friends toolIntroduceIcon"
									style="color: #d2372c"
								></i>
								<p class="mt-2 toolIntroduceText">學生紀錄繳費</p>
							</div>
						</el-button>
						<el-button class="toolIntroduceBtn ms-0" link>
							<div class="toolIntroduceBtnInfo">
								<i
									class="fa-sharp fa-light fa-atom toolIntroduceIcon"
									style="color: #4de1ea"
								></i>
								<p class="mt-2 toolIntroduceText">喬米GPT</p>
							</div>
						</el-button>
					</div>
					<div class="my-5 px-3 col-12 col-md-6 mx-auto">
						<el-carousel
							motion-blur
							:height="$store.getters.mobileSize ? '450px' : '600px'"
							interval="4000"
						>
							<el-carousel-item v-for="item in descriptionData" :key="item">
								<h3
									class="smallSlogan justify-center toolIntroduceInfoText"
									text="2xl"
									v-html="item.desc"
								></h3>
								<el-image class="introduce-image" :src="item.imageSrc" />
							</el-carousel-item>
						</el-carousel>
					</div>
				</div>
			</div>
		</section>
		<section class="pt-5 mb-5">
			<div class="row align-items-center">
				<div class="col-12 col-md-6 mb-md-0 mb-3">
					<el-image
						class="featureImage bg-white"
						:src="
							require('../../assets/image/ChargeMeContactBook/Home/常見問題.svg')
						"
					></el-image>
				</div>
				<div class="col-12 col-md-6">
					<h1 class="bigSlogan text-start mb-3">常見問題</h1>
					<el-collapse v-model="activeNames" @change="handleChange" accordion>
						<el-collapse-item
							v-for="(item, index) in commomProblem"
							:key="item"
							:title="item.question"
							:name="index"
						>
							<div class="collapse_answer" v-html="item.answer"></div>
						</el-collapse-item>
					</el-collapse>
				</div>
			</div>
		</section>
		<section class="pt-5">
			<div class="row">
				<div class="col-12">
					<h1 class="bigSlogan text-start mb-3">使用者體驗分享</h1>
				</div>
				<div class="col-12">
					<div
						v-for="item in experienceDatas"
						:key="item"
						class="experienceFeedback"
					>
						<div class="d-flex">
							<el-avatar
								:size="70"
								:src="item.avatar"
								class="experienceFeedbackAvatar"
							/>
							<div
								class="ms-3 d-flex flex-column smallSlogan my-0 justify-content-center"
							>
								<p class="text-start mb-2 experienceFeedbackTitle">
									{{ item.title }}
								</p>
								<p class="text-start">{{ item.talk }}</p>
							</div>
						</div>
						<p class="text-start mt-3 experienceFeedbackInfo">
							{{ item.feedback }}
						</p>
					</div>
				</div>
			</div>
		</section>
		<section class="my-5">
			<el-image
				:src="
					require('../../assets/image/ChargeMeContactBook/Home/頁中插圖.svg')
				"
			>
			</el-image>
		</section>
		<!-- <section class="py-5">
      <div class="row align-items-stretch">
        <div v-for="(item, index) in feeDatas" :key="item" class="col-12 col-md-4 mb-md-0 mb-3">
          <div class="feeCard h-100">
            <el-image class="feeCardImage" :src="item.image"></el-image>
            <p class="feeCardTitle">{{ item.title }} <span class="hot" v-if="index == 2">熱門</span></p>
            <p class="feeCardPrice">
              {{ item.price }}<span class="feeCardPriceUnit">{{ item.priceUnit }}</span>
            </p>
            <el-button class="feeCardButton" @click="toPagePayment(index)">{{ index == 0 ? "免費使用" : "購買" }}</el-button>
            <div>
              <p v-for="t in item.toolItems" :key="t" class="mb-2"><span class="me-2">•</span> {{ t }}</p>
            </div>
          </div>
        </div>
      </div>
    </section> -->
		<section class="">
			<h1 class="midslogan">馬上免費註冊</h1>
			<p class="smallSlogan my-5">
				現在就更有效率的管理時間,試用完隨時可以免費取消
			</p>
			<div class="mb-5">
				<el-button class="AddLineBtn" @click="toPageLine()">
					加入喬米GPT
				</el-button>
				<el-button
					class="freeTryBtn"
					@click="toPage('/ChargeMeContactBook/ClassRoomCenter')"
				>
					免費使用</el-button
				>
			</div>

			<el-image
				:src="
					require('../../assets/image/ChargeMeContactBook/Home/頁尾插圖.svg')
				"
			>
			</el-image>
		</section>

		<!-- <section class="mt-5 pt-5">
      <h1 class="midslogan">下載手機APP</h1>
      <p class="smallSlogan my-5">Android APK 下載後，請至設定開啟通知</p>
      <div class="mb-5 mt-5">
        <el-button class="AddLineBtn bg-black" @click="downLoadApk()">
          <i class="fab fa-android me-2" style="color: #3ddc84"></i>
          下載APK</el-button
        >
      </div>
    </section> -->
		<section class="bg-main-color my-3">
			<div class="row align-items-top p-3">
				<div class="col-12 col-lg-6">
					<h2 class="mb-3 mt-3 text-start smallSlogan">頁面導覽</h2>
					<div class="me-2">
						<p class="text-start text-dark aboutInfo">
							<span
								v-for="item in TermItems"
								:key="item"
								class="Terms"
								@click="openPDF(item.path)"
							>
								{{ item.title }}
							</span>
						</p>
					</div>
				</div>
				<div class="col-12 col-lg-6">
					<h2 class="mb-3 mt-3 text-start smallSlogan">關於我們</h2>
					<div class="d-flex">
						<div class="me-2">
							<p class="text-start text-dark mb-2">喬米應用數據有限公司</p>
							<p class="text-start text-dark aboutInfo">
								地址: 新竹市 北區 經國路二段100號7樓<br />
								客服信箱: service@chargeme.com.tw <br />
								營業時間: 週一至週五 8:00 ~ 17:00
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
	<div></div>
</template>

<script>
import Storage from "../../models/storage";
export default {
	name: "Home",
	data() {
		return {
			canHomeShow: false,
			civilService: [
				{
					src: '<i class="fad fa-users-crown fa-lg" style="--fa-primary-color:#ffb703; --fa-secondary-color: #fee440;width:100px"></i>',
					title: "資訊與權限控管",
					description:
						"老師可以透過成員角色的設定，來調整、分流要給個別學生、家長的資訊。",
				},
				{
					src: '<i class="fad fa-computer-speaker  fa-lg" style="--fa-primary-color:#ffb703;  --fa-secondary-color: #fee440;width:100px"></i>',
					title: "考勤與繳費整合",
					description:
						"(學生、家長)透過網頁、Linebot，進入課程打卡頁面，紀錄打卡時間<br/>(老師)協助學生打卡與補打卡",
				},
				{
					src: '<i class="fad fa-pencil-ruler fa-lg" style="--fa-primary-color:#ffb703;  --fa-secondary-color:  #fee440; width:100px"></i>',
					title: "作業與進度掌握",
					description:
						"(學生、家長)透過作業清單: 快速查看未完成作業，並可直接勾選完成或上傳作業。<br/>(老師)透過作業清單: 快速查看作業繳交狀況，並可直接檢視批閱，及留言指導。",
				},
				{
					src: '<i class="fad fa-file-excel fa-lg" style="--fa-primary-color: #ffb703;  --fa-secondary-color: #fee440; width:100px"></i>',
					title: "Excel或PDF匯出",
					description:
						"Excel或PDF匯出可將打卡、繳費資料，匯出為Excel或是PDF文件，除了可以留存歸檔外，還可以依據自身需求進行資料分析。",
				},
			],
			TermItems: [
				{
					title: "服務條款",
					path: "https://chargemestorage.blob.core.windows.net/shared-file/service_policy.pdf",
				},
				{
					title: "隱私權政策",
					path: "https://chargemestorage.blob.core.windows.net/shared-file/privacy_policy.pdf",
				},
				{
					title: "退貨退款政策",
					path: "https://chargemestorage.blob.core.windows.net/shared-file/refund_policy.pdf",
				},
				{
					title: "消費者權益聲明",
					path: "https://chargemestorage.blob.core.windows.net/shared-file/consumer_rights_statement.pdf",
				},
				{
					title: "購物流程與購買須知",
					path: "https://chargemestorage.blob.core.windows.net/shared-file/purchase_process.pdf",
				},
			],
			tableData: [
				{
					title: "牌價",
					free: "免費使用",
					Standard_Month: "280元",
					Standard_Year: "2800元/年",
				},
				{
					title: "試營運價(85折)",
					free: "免費使用",
					Standard_Month: "240元",
					Standard_Year: "2380元/年",
				},
				{
					title: "聯絡簿",
					free: "⚫",
					Standard_Month: "⚫",
					Standard_Year: "⚫",
				},
				{
					title: "購買",
					free: "",
					Standard_Month: "btn",
					Standard_Year: "btn",
				},
			],
			descriptionData: [
				{
					desc: "強大的聯絡簿公告及留言問題回饋功能<br/>縮短與學生及家長的溝通時間",
					imageSrc:
						"https://chargemestorage.blob.core.windows.net/chargeme-image/資訊介紹.png",
				},
				{
					desc: "便利的作業派送功能 提供多樣性的發送內容 作業資訊更詳細",
					imageSrc:
						"https://chargemestorage.blob.core.windows.net/chargeme-image/todo介紹.png",
				},

				{
					desc: "透過打卡紀錄 及時瀏覽剩餘課堂",
					imageSrc:
						"https://chargemestorage.blob.core.windows.net/chargeme-image/繳費介紹.png",
				},
			],
			commomProblem: [
				{
					question: "學費要繳錢了嗎?",
					answer:
						"系統Line機器人自動通知繳費，透過打卡功能系統自動計算剩餘堂數，即時線上更改上課時間不用擔心忘記上課",
				},
				{
					question: "學生聯絡簿忘了帶?",
					answer:
						"線上聯絡簿解決忘了帶的問題，透過聯絡簿公告可立即掌握上課進度",
				},
				{
					question: "學生作業交了嗎?",
					answer:
						"透過課程作業清單快速查看作業繳交狀況、系統Line機器人自動通知繳交作業",
				},
				{
					question: "家長課後頻繁詢問小孩狀況?",
					answer: "聯絡簿公告及留言問題回饋功能解決溝通問題",
				},
				{
					question: "問卷總是已讀不回嗎",
					answer: "可透過作業逾期通知，由Line機器人自動通知繳交",
				},
				{
					question: "何謂喬米GPT？",
					answer:
						"利用喬米聯絡簿記事本建立教學筆記或者學習筆記，記事本支援Google drive雲端資料串接，透過喬米秘書GPT 的Line官方帳號來快速編輯您自己資料庫的資訊，可做為「回答學生或者家長」的智能客服，也可作為「個人資料快速查詢」的私人智能客服。",
				},
			],
			experienceDatas: [
				{
					avatar: require("../../assets/image/ChargeMeContactBook/Home/線上美語教學老師.svg"),
					title: "線上美語教學老師",
					talk: "我終於可以好好改線上作業了！",
					feedback:
						"因為疫情關係，整個線上教學大爆炸，課程管理的需求也跟著大增，雖然原本教室有提供類似電子聯絡簿的功能，但操作上非常不便，不只老師自己不知道如何使用外，學生與家長更是搞不清楚狀況，相關作業繳交雖可以上傳，但有檔案類型的限制，導致最後都是跟家長用Line聯繫，拉了好幾個群組，每次交作業都要一個一個去核對這是誰的，相當不方便，但使用喬米以後，每堂課程一目了然，聯絡簿的作業清單已完成未完成都顯示清楚，學生上傳作業不限檔案類型，我可以直接批改寫意見及相關修正，聯絡簿的公告功能也相當方便，不同家長同樣問題都不會重複詢問，重點是不管老師、家長或學生都很好上手，非常便利，減少了很多不必要的溝通時間。",
				},
				{
					avatar: require("../../assets/image/ChargeMeContactBook/Home/鋼琴老師.svg"),
					title: "鋼琴家教老師",
					talk: "可以不用計堂數真的很爽！",
					feedback:
						"我對聯絡簿的依賴相當大，因為上面有學生課程進度、繳費紀錄、上課堂數紀錄等等，但最常遇到的狀況就是學生常常忘了帶聯絡簿，更有甚者聯絡簿直接搞丟，若學生又是沒記性的，想要問進度更是沒下落，狂論要看繳費紀錄或者上課堂數等等，因為鋼琴家教大多預收學費，所以很需要紀錄剩幾堂課，加上學生遇到段考或者寒暑假經常會請假、調課等等，沒有紀錄很難知道還剩幾堂課，自從使用喬米後，聯絡簿改為線上後，完全不用擔心學生沒帶聯絡簿問題，還不用去翻聯絡簿紀錄上次上課進度，作業清單立馬知道進度到哪，線上打卡功能更是解決上課堂數紀錄問題，系統會主動幫您紀錄，可以清楚知道還剩幾堂課，家長可以知道何時要繳費，像我這種臉皮薄的老師，不好意思一直提醒家長要繳費，可以利用系統設定的Line機器人去主動通知家長要記得繳費，我自己忙到忘記學生沒堂數要繳費，家長會自動繳給我，多省時省力。",
				},
				{
					avatar: require("../../assets/image/ChargeMeContactBook/Home/安親班老師.svg"),
					title: "安親班老師",
					talk: "不用再接到家長電話詢問何時可以接小孩！",
					feedback:
						"其他安親班怎麼管理學生我不清楚，但我在的安親班最常遇到一個問題，因為有要求學生要完成學校的回家作業，家長才能把小孩接回家，但不同學校、不同年級、不同班級的學生作業量本就不同，加上學生素質不一，所以每位學生可以完成作業的時間都不一樣，但我們要顧的學生不算少，很難顧及哪個學生完成作業可以通知家長隨時來接小孩，導致安親班外面常常有家長在等小孩寫完作業才能接小孩回家的狀況，使用喬米後，透過Line機器人設定，哪個學生完成作業後，只要簡單點選就可以主動知家長來接學生，過去安親班門口塞滿等待家長的狀況就消失了！而且聯絡簿公告功能很方便，加上我們安親班又有才藝班的課程，一樣透過喬米去管理，大大減輕安親班的行政負擔。",
				},
				{
					avatar: require("../../assets/image/ChargeMeContactBook/Home/家長.svg"),
					title: "家長",
					talk: "虎爸虎媽最好用的聯絡簿！",
					feedback:
						"相信各位當爸媽的家長們在小孩上幼稚園開始，常常下班後接到小孩回到家吃完飯，就是催促小孩把聯絡簿拿出來，若為了栽培小孩又有去上其他才藝課的又有其他聯絡簿，每次都搞了好幾本，最氣的就是小孩常常忘了帶聯絡簿回家，根本不曉得有哪些作業要做，老師有沒有提醒什麼事？到底小孩目前進度到哪？因為自己小孩對鋼琴有興趣，所以一直有在額外上鋼琴課程，老師因為有使用喬米聯絡簿，我才知道原來現在有這麼方便的電子聯絡簿可以使用，在課程的作業清單可以清楚知道小孩回家要練習的曲子區段及練習重點，老師還會要求小孩要錄製練習的影片或者錄音上傳到聯絡簿，老師若看了覺得哪裡有問題，會在聯絡簿公告提醒哪裡要調整及注意，下次上課前就不用花很多時間在糾正小孩錯誤的彈法，讓課程更有進度及效率，最重要的是不用擔心小孩沒抄聯絡簿或者忘了把聯絡簿帶回家，真希望所有老師都能使用喬米聯絡簿，這樣我就不用翻這麼多本聯絡簿了XD",
				},
			],
			feeDatas: [
				{
					image: require("../../assets/image/ChargeMeContactBook/Home/免費.svg"),
					title: "試用",
					price: "$0",
					priceUnit: "",
					toolItems: ["免費使用"],
				},
				{
					image: require("../../assets/image/ChargeMeContactBook/Home/月繳.svg"),
					title: "特仕",
					price: "$99/1000",
					priceUnit: "(月繳/年繳)",
					toolItems: ["開課堂數:5", "檔案上傳:1G", "適用:兼職教師"],
				},
				{
					image: require("../../assets/image/ChargeMeContactBook/Home/年繳.svg"),
					title: "專業",
					price: "$219/2190",
					priceUnit: "(月繳/年繳)",
					toolItems: ["開課堂數:無限制", "檔案上傳:5G", "適用:全職教師"],
				},
			],
			classData: [],
			teacherData: [],
			carouselImage: [
				"https://images.unsplash.com/photo-1507838153414-b4b713384a76?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
				"https://images.unsplash.com/photo-1514119412350-e174d90d280e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
				"https://images.unsplash.com/photo-1477233534935-f5e6fe7c1159?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
				"https://images.unsplash.com/photo-1496293455970-f8581aae0e3b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1113&q=80",
			],
			// IsMobileSize: true,
		};
	},
	computed: {
		IsMobileSize() {
			if (this.$store.getters.mobileSize) return true;
			else return false;
		},
		musicClassFilter() {
			if (this.$store.getters.mobileSize) return this.classData.slice(0, 4);
			else return this.classData.slice(0, 8);
		},
		teacherFilter() {
			if (this.$store.getters.mobileSize) return this.teacherData.slice(0, 4);
			else return this.teacherData.slice(0, 8);
		},
	},
	mounted() {
		this.log_PageInfo("HomePage", "1");
		window.addEventListener("scroll", this.handleScroll, true);

		const code = this.$route.query.code;
		if (code) {
			this.authorize(code);
		} else {
			this.canHomeShow = true;
		}
	},
	methods: {
		async authorize(code) {
			const vm = this;

			vm.$store.state.isloading = true;
			if (code == "" || code == null) {
				await vm.$router.push("/login");
			}

			let user = vm.$store.state.auth.user;
			let userId = null;
			if (user != null) userId = user.id;

			//accountBind(快速連結)
			if (user == null && Storage.get("accountBind") != undefined) {
				userId = Storage.get("accountBind").id;
				Storage.remove("accountBind");
			}

			let par = { AuthCode: code, UserId: userId };

			await vm.$store
				.dispatch("auth/googleLogin", par)
				.then(() => {
					let name = vm.$store.state.auth.user.name;
					let message = name === "" ? "登入成功" : `${name}，歡迎回來！`;
					vm.notify("success", message);
					vm.$router.push(Storage.get("ChargeMe_Setting").page);
				})
				.catch(() => {
					Storage.remove("user");
					vm.notify("error", "登入失敗！");
					vm.$router.push("/login");
				})
				.finally(() => {
					vm.$store.state.isloading = false;
					vm.canHomeShow = false;
				});
		},
		downLoadApk() {
			window.location.href =
				"https://chargemestorage.blob.core.windows.net/chargeme-file/ChargeMe.apk";
		},
		toPagePayment(index) {
			if (index == 0) {
				this.$router.push("/ChargeMeContactBook/ClassRoomCenter");
			} else if (index == 1) {
				this.$router.push("/ChargeMeContactBook/Payment?memberLevel=Special");
			} else {
				this.$router.push("/ChargeMeContactBook/Payment?memberLevel=Pro");
			}
		},
		toPage(turnPage) {
			Storage.set("ChargeMe_Setting", {
				page: "/ChargeMeContactBook/ClassRoomCenter",
			});
			if (this.currentUser() == null) {
				this.$router.push("/login");
			} else this.$router.push(turnPage);
		},
		toPageLine() {
			window.location.href = "https://lin.ee/IIn25x4";
		},
		openPDF(pdfUrl) {
			window.open(pdfUrl);
		},
		handleScroll() {
			if (this.$route.path !== "/" || this.$route.path !== "/home") return;
			let scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop;

			if (scrollTop > 10) {
				document.getElementById("navbar").classList.add("scrollBgc");
			} else {
				document.getElementById("navbar").classList.remove("scrollBgc");
			}
		},
	},
};
</script>

<style lang="scss" scoped>
* {
	color: $main_font_color;
}

::v-deep .el-collapse-item__header {
	padding: 2rem 0;
	font-size: 24px;
	font-weight: 600;
	font-family: "Inter", sans-serif;
}

::v-deep .el-collapse {
	--el-collapse-border-color: black !important; /* 改變隔離線顏色 */
	font-family: "Inter", sans-serif;
}
.bg-main-color {
	background-color: $main_color;
	border-radius: 16px;
}
.collapse_answer {
	font-size: 20px;
	text-align: start;
}
.securityTitle {
	font-size: large;
	font-weight: 600;
	margin-bottom: 0.5rem;
}
.securityDesc {
	line-height: 1.5;
	margin-bottom: 1rem;
}
.civilServiceTitle {
	color: #e06666;
	font-size: large;
	font-weight: 600;
	margin-bottom: 0.5rem;
}
.civilServiceDesc {
	line-height: 1.5;
}

.badge {
	background-image: linear-gradient(
		to top,
		rgba(255, 255, 255, 0.8),
		transparent 70%
	);
	border-radius: 0px !important;
	height: 30px;
	font-size: 18px;
}
.musicClass {
	background-color: #f6f6f6;
}
.AttendClass {
	background: $main_color;
	color: white;
	border: none;
	width: 200px;
	padding: 0.375rem 0.75rem;
	border-radius: 4px;
}
a {
	text-decoration: none;
	color: rgba(0, 0, 0, 0.55);
	&:hover {
		color: rgba(0, 0, 0, 0.55);
	}
}
#carousel {
	width: 100vw;
}
.featureCard {
	display: flex;
	.Info {
		text-align: left;
		border-left: 2px solid $main_color;
		padding-left: 1rem;
		margin-left: 1rem;
		.h4 {
			color: black;
		}
		.content {
			line-height: 1.5;
		}
	}
	img {
		width: 100px;
	}
}
.baseline {
	border-color: #ffd964 !important;
}
.el-carousel {
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
.consult {
	height: 20rem;
	background-color: rgb(214, 214, 214);
	padding: 0;
	box-shadow: 1px 1px 3px 1px rgb(206, 205, 205);
	transition: 0.2s;
	&:hover {
		box-shadow: 3px 3px 5px 3px rgb(206, 205, 205);
	}
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
.card {
	img {
		width: 100%;
		height: 200px;
		object-fit: cover;
	}
	.info {
		height: 2rem;
		overflow: hidden;
	}
	&:hover {
		box-shadow: 3px 3px 5px 3px rgb(206, 205, 205);
		cursor: pointer;
	}
}
.teacher {
	img {
		height: 20vh;
	}
}

.bigSlogan {
	font-size: 60px;
	font-family: "Inter", sans-serif;
	font-weight: bolder;
	line-height: 1.25;
}
.midslogan {
	font-family: "Inter", sans-serif;
	font-weight: bold;
	font-size: 40px;
}
.smallSlogan {
	font-size: 24px;
	font-family: "Inter", sans-serif;
	font-weight: 600;
	margin: 2rem 0;
	line-height: 1.25;
}
.freeTryBtn {
	font-size: 30px;
	font-family: "Inter", sans-serif;
	color: white;
	background-color: #0081f2;
	padding: 2rem;
}
.AddLineBtn {
	font-size: 30px;
	font-family: "Inter", sans-serif;
	background-color: #06c755;
	color: white;
	padding: 2rem;
}

.introduce-image {
	width: 100%;
}
.toolIntroduceText {
	font-family: "Inter", sans-serif;
	font-weight: bold;
}
.toolIntroduceInfoText {
	font-family: "Inter", sans-serif;
	font-size: medium;
	margin-bottom: 1rem;
}

.experienceFeedback {
	font-family: "Inter", sans-serif;

	background-color: #f7f5f4;
	padding: 1rem;
	border-radius: 16px;
	margin-bottom: 25px;
	.experienceFeedbackInfo {
		font-size: 16px;
		line-height: 1.5;
		letter-spacing: 0.1em;
	}
	.experienceFeedbackAvatar {
		min-width: 70px;
	}
	.experienceFeedbackTitle {
		font-size: 16px;
	}
}
.feeCard {
	font-family: "Inter", sans-serif;
	background-color: #f7f5f4;
	padding: 1rem;
	border-radius: 16px;
	text-align: start;
	font-weight: bold;

	.feeCardImage {
	}
	.feeCardTitle {
		font-size: 16px;
		margin: 10px 0;
		.hot {
			padding: 2px 10px;
			border-radius: 99rem;
			color: white;
			background-color: #0081f2;
			margin-left: 10px;
		}
	}
	.feeCardPrice {
		font-size: 30px;
		.feeCardPriceUnit {
			margin-left: 5px;
			font-size: 16px;
		}
	}
	.feeCardButton {
		margin: 20px 0;
		border: none;
		font-size: 20px;
		background-color: black;
		color: white;
		width: 100%;
		padding: 20px;
		font-weight: bold;
		&:hover {
			background-color: white;
			color: black;
		}
	}
}
.Description-layer {
	border-radius: 0.5rem;
	padding: 1rem 1rem;
	background-color: rgba(255, 255, 255, 0.7);
}

.aboutInfo {
	font-size: small;
	line-height: 2;
	display: flex;
	flex-direction: column;
	.Terms {
		&:hover {
			text-decoration: underline;
		}
	}
}
.toolIntroduceBtn {
	.toolIntroduceBtnInfo {
		font-size: 24px;
		i {
			font-size: 30px;
			margin-bottom: 10px;
		}
	}
}
.fixed-Area {
	background-color: white;
	border-radius: 5px;
	padding: 5px;
	border: 1px solid rgba(0, 0, 0, 0.25);
	z-index: 88;
	display: flex;
	flex-direction: column;
	position: fixed;
	right: 10px;
	top: 80%;
}
.freeTryBtn-fixed {
	font-size: 14px;
	font-family: "Inter", sans-serif;
	color: white;
	background-color: #0081f2;
	padding: 0 0.5rem;
}

.lineBackground {
	background-color: #06c755;
}
@media screen and (max-width: 768px) {
	.bigSlogan {
		font-size: 30px;
	}
	.smallSlogan {
		font-size: 20px;
	}
	.freeTryBtn {
		font-size: 20px;
		padding: 1.25rem;
	}
	.AddLineBtn {
		font-size: 20px;
		padding: 1.25rem;
	}
	.toolIntroduceBtn {
		.toolIntroduceBtnInfo {
			font-size: 15px;
			i {
				font-size: 30px;
				margin-bottom: 10px;
			}
		}
	}
}
</style>
